<template>
  <div class="filters">
    <div class="filter_block">
      <custom-input
          :delay="400"
          label="Имя"
          name="name"
          v-model="searchParams.nameField"
          clear
      />
      <custom-input
          :delay="400"
          label="Город"
          name="city"
          v-model="searchParams.cityField"
          clear
      />
      <custom-input
          :delay="400"
          label="Партнёр"
          name="partner"
          v-model="searchParams.partnerField"
          clear
      />
    </div>
    <div class="search_btn search__clear-btn">
      <button @click="clearAllSearch" type="button" class="btn btn-w btn-sm">
        Очистить
      </button>
    </div>
  </div>

  <div v-if="!loadingTable">
    <div v-if="shownData.length">
      <base-table
          v-if="shownData.length"
          :columns="shownFields.value"
          :rows="shownData"
          @sort="(data) => {
            sortValue = data.sortValue;
            sortDirection = data.direction;
          }"
      />
      <table-pagination
          v-if="shownData.length"
          :key="updatePagination"
          :totalPages="1"
          :countOnPage="countOnPage"
          :total="totalResults ?? rowData.length"
          :count="countOnPage > shownData.length ? shownData.length : countOnPage"
          @changePage="changePage"
      />
    </div>
    <div v-else class="no_results">
      <p>Нет результатов</p>
    </div>
  </div>
  <div v-else class="table-preloader">
    <preloader/>
  </div>
</template>

<script>
import {reactive, ref} from "@vue/reactivity";
import {useStore} from "vuex";
import {computed, onBeforeMount, watch} from "@vue/runtime-core";
import Preloader from "@/components/Technical/Preloader.vue";
import CustomTextFilter from "@/components/Tables/FiltersComponents/CustomTextFilter.vue";
import CustomInput from "@/components/Forms/Fields/CustomInput.vue";
import TablePagination from "@/components/OtherElements/TablePagination.vue";
import useCheckRights from "@/mixins/useCheckRights";
import sortData from "@/mixins/sortData";
import BaseTable from "@/components/Tables/BaseTable.vue";
import {watchEffect} from "vue";

export default {
  name: "partners-table",
  components: {
    BaseTable,
    Preloader,
    CustomInput,
    CustomTextFilter,
    TablePagination,
  },
  setup() {
    const store = useStore();

    const {getRight} = useCheckRights();

    const countOnPage = ref(15),
        isPreloader = ref(false),
        searchParams = reactive({
          nameField: "",
          cityField: "",
          partnerField: "",
        });
    const experts = computed(() => store.state.partners.experts),
        rowData = computed(() => {
          return experts.value.map((expert) => {
            return {
              id: expert.id,
              name: expert.title,
              city: expert.city ? expert.city.name : "",
              edit: expert.id,
              new: expert.status,
              image: expert.image,
              user: expert.user.name,
              user_id: expert.user.id,
              partner_id: expert.partner?.id,
              partner: expert.partner?.title,
            };
          });
        });
    const loadingTable = computed(() => store.state.ui.loadingTable);
    const profile = computed(() => store.state.profile.profile);

    let expertFields = reactive({
      value: [
        {
          field: "partner_renderer",
          headerName: "",
        },
        {
          field: "image",
          headerName: "Фото",
        },
        {
          field: "name",
          headerName: "Имя",
          sortable: true,
          link: true,
          dest: "expert"
        },
        {
          field: "city",
          headerName: "Город",
        },
        {
          field: "user",
          headerName: "Пользователь",
          link: true,
          dest: "staff",
          res: "edit",
          linkId: 'user_id',
        },
        {
          field: "partner",
          headerName: "Партнер",
          link: true,
          dest: "partner",
          linkId: 'partner_id',
        },
        {
          field: "edit",
          headerName: "",
          dest: "expert",
        },
      ]
    });

    let shownFields = reactive({
      value: []
    });

    const pageDataStart = ref(0);
    const sortValue = ref('');
    const sortDirection = ref('');
    const updatePagination = ref(false);
    const totalResults = ref();

    const shownData = computed(() => {
      let data = rowData.value ?? [];

      if (data.length) {

        if (searchParams.cityField && searchParams.cityField.length >= 3) {
          data = data.filter((el) => el.city.toLowerCase().includes(searchParams.cityField.toLowerCase()));
        }

        if (searchParams.partnerField && searchParams.partnerField.length >= 3) {
          data = data.filter((el) =>
              el.partner.toLowerCase().includes(searchParams.partnerField.toLowerCase())
          );
        }

        if (searchParams.nameField && searchParams.nameField.length >= 3) {
          data = data.filter((el) => el.name.toLowerCase().includes(searchParams.nameField.toLowerCase() ||
              el.user.toLowerCase().includes(searchParams.nameField.toLowerCase())));
        }

        if (sortValue.value) {
          data = sortData(data, sortValue.value, sortDirection.value);
        } else {
          data = sortData(data, 'new', 'up');
        }

        totalResults.value = data.length;
        data = data.slice(pageDataStart.value, pageDataStart.value + countOnPage.value);
      }

      return data;
    });

    const changePage = (page) => {
      pageDataStart.value = (page - 1) * countOnPage.value;
    };

    const clearAllSearch = () => {
      searchParams.nameField = "";
      searchParams.partnerField = "";
      searchParams.cityField = "";
    };

    onBeforeMount(() => {

      if (!getRight("partnersList")) {
        shownFields.value = expertFields.value.filter((el) => el.field !== 'partner_renderer' && el.field !== 'partner')
      } else {
        shownFields.value = expertFields.value;
      }

      store.commit("ui/setLoadingTable", true);
      store
          .dispatch("partners/getExperts")
          .finally(() => store.commit("ui/setLoadingTable", false));
    });

    watch(
        () => [sortDirection.value, sortValue.value, countOnPage.value,
          searchParams.cityField, searchParams.nameField,
          searchParams.partnerField],
        () => {
          totalResults.value = 0;
          pageDataStart.value = 0;
          updatePagination.value = !updatePagination.value;
        },
    )

    watch(() => [profile.value], () => {
      if (!getRight("partnersList")) {
        shownFields.value = expertFields.value.filter((el) => el.field !== 'partner_renderer' && el.field !== 'partner')
      } else {
        shownFields.value = expertFields.value;
      }
    })

    return {
      countOnPage,
      isPreloader,
      experts,
      searchParams,

      loadingTable,
      rowData,

      shownData,
      updatePagination,
      changePage,
      sortValue,
      sortDirection,
      totalResults,
      clearAllSearch,
      shownFields,
    };
  },
};
</script>

<style lang="scss" scoped>
.no_results {
  padding: 40px 10px;
}

.filters {
  display: flex;
  row-gap: 5px;
  flex-direction: column;

}

.filter_block {
  display: flex;
  flex-wrap: wrap;
}

.filter_block .form-group {
  width: 400px;
}

.search_btn {
  margin-top: 10px;
}

@media (max-width: 768px) {
  .filter_block {
    flex-direction: column;
    width: 100%;
  }

  .filter_block .form-group {
    width: 100%;
  }
}
</style>
